/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import NProgress from 'nprogress';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../lib/useApollo';
import AppContextProvider from '../context/Provider';
import ToastContextProvider from '../context/ToastProvider';
import '../styles.css';

// @ts-ignore
function MyApp({ Component, pageProps, err }: AppProps) {
  const router = useRouter();
  const apolloClient = useApollo(pageProps.initialApolloState);
  React.useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, [router.events]);
  return (
    <ApolloProvider client={apolloClient}>
      <>
        <Head>
          <title>Admin | BodeBar</title>
        </Head>
        <AppContextProvider>
          <ToastContextProvider>
            <Component {...pageProps} err={err} />
          </ToastContextProvider>
        </AppContextProvider>
      </>
    </ApolloProvider>
  );
}

export default MyApp;
